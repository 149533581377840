<template>
  <div class="parking-settings">
    <v-card class="elevation-0" outlined v-if="parking.length">
      <v-card-title class="grey lighten-2">
        Parking
      </v-card-title>
      <v-card-text class="pt-4">
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title>Parkplätze Total</v-list-item-title>
            <v-list-item-subtitle>
              {{ parkingTotal }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title>Parkplätze Mieter</v-list-item-title>
            <v-list-item-subtitle>
              {{ parkingTotalInhouse }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title>Parkplätze Fremd</v-list-item-title>
            <v-list-item-subtitle>
              {{ parkingTotalExternal }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title>Aktuell Tickets</v-list-item-title>
            <v-list-item-subtitle>
              {{ parkingCurrentSkidata }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line class="px-0">
          <v-list-item-content class="pb-0">
            <v-list-item-title>Parkplätze Fremd</v-list-item-title>
            <v-list-item-subtitle>
              <v-row class="mb-0">
                <v-col class="py-0">
                  <v-text-field outlined dense v-model="parkingCurrentRMS" />
                </v-col>
                <v-col class="py-0" cols="auto">
                  <v-btn
                    class="elevation-0"
                    color="primary"
                    @click="$refs.parkingConfirmRMSDialog.open = true"
                  >
                    Überschreiben
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line class="px-0">
          <v-list-item-content class="pb-0">
            <v-list-item-title>Aktuell reserviert belegt</v-list-item-title>
            <v-list-item-subtitle>
              <v-row class="mb-0">
                <v-col class="py-0">
                  <v-text-field outlined dense v-model="parkingCurrentLogs" />
                </v-col>
                <v-col class="py-0" cols="auto">
                  <v-btn
                    class="elevation-0"
                    color="primary"
                    @click="$refs.parkingConfirmLogsDialog.open = true"
                  >
                    Überschreiben
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
    <confirm-dialog
      ref="parkingConfirmRMSDialog"
      :confirmMessage="
        'Soll der Wert in der SIPORT-Datenbank überschrieben werden?'
      "
      @confirmed="setParkingCurrentRMS"
    />
    <confirm-dialog
      ref="parkingConfirmLogsDialog"
      :confirmMessage="
        'Soll der Wert in der SIPORT-Datenbank überschrieben werden?'
      "
      @confirmed="setParkingCurrentLogs"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";

export default {
  props: ["settings"],

  components: {
    ConfirmDialog
  },

  data: () => ({
    parking: []
  }),

  watch: {
    settings(value) {
      this.parking = [
        ...this.settings.filter(setting => setting.category === "parking")
      ];
    }
  },

  computed: {
    parkingTotal() {
      return this.parking
        ? this.parking.find(setting => setting.key === "total").value
        : null;
    },
    parkingTotalInhouse() {
      return this.parking
        ? this.parking.find(setting => setting.key === "total_inhouse").value
        : null;
    },
    parkingTotalExternal() {
      return this.parking
        ? this.parking.find(setting => setting.key === "total_external").value
        : null;
    },
    parkingCurrentSkidata() {
      return this.parking
        ? this.parking.find(setting => setting.key === "current_skidata").value
        : null;
    },
    parkingCurrentRMS: {
      get() {
        return this.parking
          ? this.parking.find(setting => setting.key === "current_rms").value
          : null;
      },
      set(value) {
        this.parking.current_rms = value;
      }
    },
    parkingCurrentLogs() {
      return this.parking
        ? this.parking.find(setting => setting.key === "current_logs").value
        : null;
    }
  },

  methods: {
    /**
     * Set current parking RMS.
     */
    async setParkingCurrentRMS() {
      await this.$api.http.put("backoffice/settings/parking-rms", {
        value: this.parking.current_rms
      });
    },

    /**
     * Set current parking Logs.
     */
    async setParkingCurrentLogs() {
      await this.$api.http.put("backoffice/settings/parking-logs", {
        value: this.parking.current_logs
      });
    }
  }
};
</script>

<style></style>
