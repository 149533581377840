<template>
  <div class="attachment-settings">
    <v-card class="elevation-0" outlined>
      <v-card-title class="grey lighten-2" style="position: relative">
        Bestuhlung

        <tooltip-button
          icon="add"
          text="Neuen Typen erfassen"
          color="default"
          :dark="false"
          @click="openCreateDialog()"
          position="top"
          size="small"
          float="bottom right"
        />
      </v-card-title>
      <v-card-text class="pt-8">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Key
                </th>
                <th class="text-right">
                  Löschen
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="seating in seatings" :key="seating.key">
                <td>{{ seating.value }}</td>
                <td>{{ seating.key }}</td>
                <td class="text-right">
                  <tooltip-button
                    icon="delete"
                    text="Löschen"
                    color="default"
                    :dark="false"
                    @click="openDeleteDialog(seating.id, seating.value)"
                    classNames="elevation-0 ml-1 mt-1 mb-1"
                    position="top"
                    size="x-small"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <seating-type-dialog ref="createDialog" @saved="$emit('updated')" />
    <delete-dialog ref="deleteDialog" @deleted="$emit('updated')" />
  </div>
</template>

<script>
import TooltipButton from "@/components/forms/TooltipButton";
import SeatingTypeDialog from "@/components/dialogs/SeatingTypeDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";

export default {
  name: "SeatingSettings",
  props: ["settings"],
  components: {
    TooltipButton,
    SeatingTypeDialog,
    DeleteDialog
  },

  computed: {
    seatings() {
      return this.settings.filter(
        setting => setting.category === "seating_types"
      );
    }
  },

  methods: {
    /**
     * Open seating create dialog
     */
    openCreateDialog() {
      this.$refs.createDialog.mode = "create";
      this.$refs.createDialog.open = true;
    },

    /**
     * Open delete dialog
     */
    openDeleteDialog(id, name) {
      this.$refs.deleteDialog.entityId = id;
      this.$refs.deleteDialog.entityName = `#${id} ${name}`;
      this.$refs.deleteDialog.entityUrl = "settings";
      this.$refs.deleteDialog.open = true;
    }
  }
};
</script>

<style scoped></style>
