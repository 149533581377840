<template>
  <v-dialog v-model="open" scrollable width="500">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="subtitle-1">Bestätigen</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>
          {{ confirmMessage }}
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="abort()">
          Nein
        </v-btn>
        <v-btn color="primary" text @click="confirm()">
          Ja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    confirmMessage: {
      type: String,
      required: true
    }
  },

  data: () => ({
    open: false
  }),

  methods: {
    /**
     * Delete entity.
     */
    confirm() {
      this.$emit("confirmed");
      this.open = false;
    },

    /**
     * Abort.
     */
    abort() {
      this.open = false;
    }
  }
};
</script>
