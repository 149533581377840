<template>
  <div class="settings mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">Einstellungen</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <crestron-settings :settings="settings" class="mb-6" />
          <parking-settings :settings="settings" />
        </v-col>
        <v-col cols="4">
          <seating-settings
            :settings="settings"
            @updated="fetchSettings()"
            class="mb-6"
          />
          <attachment-settings :settings="settings" />
        </v-col>
        <v-col cols="4">
          <recipients-settings v-model="settings" @updated="fetchSettings()" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ParkingSettings from "@/components/settings/ParkingSettings.vue";
import CrestronSettings from "@/components/settings/CrestronSettings.vue";
import AttachmentSettings from "@/components/settings/AttachmentSettings.vue";
import SeatingSettings from "@/components/settings/SeatingSettings";
import RecipientsSettings from "../components/settings/RecipientsSettings";

export default {
  name: "settings",

  components: {
    RecipientsSettings,
    ParkingSettings,
    CrestronSettings,
    AttachmentSettings,
    SeatingSettings
  },

  data: () => ({
    settings: []
  }),

  created() {
    this.fetchSettings();
  },

  methods: {
    /**
     * Fetch settings from server.
     */
    async fetchSettings() {
      const response = await this.$api.http.get("backoffice/settings");
      this.settings = response.data;
    }
  }
};
</script>
