<template>
  <div class="attachment-settings">
    <v-card class="elevation-0" outlined>
      <v-card-title class="grey lighten-2">
        E-Mail-Anhänge
      </v-card-title>
      <v-card-text class="pt-4">
        <h3 class="mb-5 black--text">Buchungsbestätigungen:</h3>
        <label class="black--text">Attachment 1:</label>
        <div v-if="organizer1 && organizer1.url">
          <a :href="organizer1.url + '?t=' + cacheBusting" target="_blank">{{
            organizer1.name
          }}</a>
        </div>
        <v-row>
          <v-col>
            <v-file-input
              label="Bild auswählen"
              accept="application/pdf"
              v-model="newOrganizer1"
            ></v-file-input>
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="uploadAttachments('organizer1', newOrganizer1)"
              class="elevation-0 mt-3"
              color="default"
              fab
              small
              :disabled="loading || !newOrganizer1"
            >
              <v-icon>file_upload</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <label class="black--text">Attachment 2:</label>
        <div v-if="organizer2 && organizer2.url">
          <a :href="organizer2.url + '?t=' + cacheBusting" target="_blank">{{
            organizer2.name
          }}</a>
        </div>
        <v-row>
          <v-col>
            <v-file-input
              label="Bild auswählen"
              accept="application/pdf"
              v-model="newOrganizer2"
            ></v-file-input>
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="uploadAttachments('organizer2', newOrganizer2)"
              class="elevation-0 mt-3"
              color="default"
              fab
              small
              :disabled="loading || !newOrganizer2"
            >
              <v-icon>file_upload</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment";

export default {
  props: ["settings"],

  data: () => ({
    loading: false,
    organizer1: null,
    newOrganizer1: null,
    organizer2: null,
    newOrganizer2: null,
    cacheBusting: moment().format("x")
  }),

  created() {
    this.fetchAttachments();
  },

  methods: {
    /**
     * Fetch attachments from server.
     */
    fetchAttachments() {
      this.$api.http
        .get(`backoffice/settings/attachments`)
        .then(response => {
          this.organizer1 = response.data.organizer1;
          this.organizer2 = response.data.organizer2;
        })
        .catch(error => {
          this.$toast.error("Attachments konnten nicht geladen werden.");
        });
    },

    /**
     * Upload or replace an attachment.
     */
    uploadAttachments(attachment, file) {
      this.loading = true;
      let formData = new FormData();
      formData.append("file", file);
      this.$api.http
        .post(`backoffice/settings/attachments/${attachment}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          this.fetchAttachments();
          this.$toast.success("Attachment wurde ersetzt.");
          this.newOrganizer1 = null;
          this.newOrganizer2 = null;
          this.loading = false;
          this.cacheBusting = moment().format("x");
        })
        .catch(error => {
          this.$toast.error(
            error.response.status === 400
              ? "Es sind nur PDF-Dateien zugelassen."
              : "Attachment konnte nicht ersetzt werden."
          );
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
