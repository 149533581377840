<template>
  <div class="recipients-settings">
    <v-card class="elevation-0" outlined>
      <v-card-title class="grey lighten-2">
        Empfänger
      </v-card-title>
      <v-card-text class="pt-4">
        <div class="mb-6">
          <p>
            An folgende Adressen wird jeweils eine Kopie des E-Mails geschickt
            (BCC).
          </p>
          <p>
            <v-icon color="info" class="mr-2">info</v-icon>
            Pro Empfänger eine neue Zeile erfassen.
          </p>
        </div>
        <v-form v-model="valid" @submit.prevent="save()">
          <v-textarea
            outlined
            dense
            v-model="confirmation"
            label="Bestätigungen"
            auto-grow
          >
          </v-textarea>
          <v-textarea
            outlined
            dense
            v-model="cancel"
            label="Stornierungen"
            auto-grow
          >
          </v-textarea>
          <v-textarea
            outlined
            dense
            v-model="rebook"
            label="Umbuchungen"
            auto-grow
          >
          </v-textarea>
          <v-btn color="primary" type="submit" elevation="0" :disabled="!valid">
            Speichern
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "RecipientsSettings",

  props: ["value"],

  data: () => ({
    valid: false
  }),

  computed: {
    confirmation: {
      get() {
        const list = this.value.find(
          s => s.category === "recipients" && s.key === "confirmation"
        );
        return list ? list.value.join("\n") : "";
      },
      set(value) {
        const settings = [...this.value];
        const index = settings.findIndex(
          s => s.category === "recipients" && s.key === "confirmation"
        );
        settings[index].value = value.split("\n");
        this.$emit("input", settings);
      }
    },
    cancel: {
      get() {
        const list = this.value.find(
          s => s.category === "recipients" && s.key === "cancel"
        );
        return list ? list.value.join("\n") : "";
      },
      set(value) {
        const settings = [...this.value];
        const index = settings.findIndex(
          s => s.category === "recipients" && s.key === "cancel"
        );
        settings[index].value = value.split("\n");
        this.$emit("input", settings);
      }
    },
    rebook: {
      get() {
        const list = this.value.find(
          s => s.category === "recipients" && s.key === "rebook"
        );
        return list ? list.value.join("\n") : "";
      },
      set(value) {
        const settings = [...this.value];
        const index = settings.findIndex(
          s => s.category === "recipients" && s.key === "rebook"
        );
        settings[index].value = value.split("\n");
        this.$emit("input", settings);
      }
    }
  },

  methods: {
    /**
     * Save addon.
     */
    save() {
      this.$api.http
        .put(`backoffice/settings`, {
          settings: this.value.filter(s => s.category === "recipients")
        })
        .then(response => {
          this.$toast.success("Die Empfänger wurden aktualisiert.");
        })
        .catch(error => {
          const message = error.response.data.message;
          this.$toast.error(message);
        });
    }
  }
};
</script>

<style scoped></style>
