<template>
  <v-dialog v-model="open" scrollable width="500">
    <v-form v-model="valid" @submit.prevent="save()">
      <v-card>
        <v-card-title class="primary white--text">
          Bestuhlungstyp erfassen
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                v-model="value"
                label="Name"
                type="text"
                :maxlength="255"
                :rules="textRules"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="key"
                label="Key"
                type="text"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="abort()">Abbrechen</v-btn>
          <v-btn color="primary" text type="submit" :disabled="!valid">
            Erstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    open: false,
    valid: false,

    value: null,

    textRules: [v => !!v || "Muss ausgefüllt sein"]
  }),

  watch: {
    open(value) {
      if (value) {
        this.resetForm();
      }
    }
  },

  computed: {
    ...mapState("app", ["configs"]),

    key() {
      return this.value
        ? this.value
            .toString()
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w\-]+/g, "")
            .replace(/\-\-+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "")
        : "";
    }
  },

  methods: {
    /**
     * Reset form.
     */
    resetForm() {
      this.value = null;
    },

    /**
     * Close modal
     */
    abort() {
      this.open = false;
    },

    /**
     * Save seating.
     */
    save() {
      this.$api.http
        .put(`backoffice/settings`, {
          settings: [
            {
              category: "seating_types",
              key: this.key,
              value: this.value
            }
          ]
        })
        .then(() => {
          this.open = false;
          this.$emit("saved");
        })
        .catch(error => {
          const message = error.response.data.message;
          this.$toast.error(message);
        });
    }
  }
};
</script>
