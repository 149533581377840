<template>
  <v-dialog v-model="open" scrollable width="500">
    <v-card>
      <v-card-title :class="[color, 'white--text']">
        <span class="subtitle-1">Löschen</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>
          Soll der Eintrag <strong>{{ entityName }}</strong> endgültig gelöscht
          werden?
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="abort()">
          Nein
        </v-btn>
        <v-btn :color="color" text @click="deleteEntity()">
          Ja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    open: false,
    entityId: null,
    entityName: null,
    entityUrl: null,
    color: "error"
  }),

  methods: {
    /**
     * Delete entity.
     */
    deleteEntity() {
      this.$api.http
        .delete(`backoffice/${this.entityUrl}/${this.entityId}`)
        .then(response => {
          this.open = false;
          this.$emit("deleted");
        })
        .catch(error => {
          `${this.entityName} konnte nicht gelöscht werden.`;
        });
    },

    /**
     * Abort.
     */
    abort() {
      this.open = false;
    }
  }
};
</script>
