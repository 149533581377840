<template>
  <div class="crestron-settings">
    <v-card class="elevation-0" outlined>
      <v-card-title class="grey lighten-2">
        Crestron
        <v-spacer></v-spacer>
        <div class="crestron-status">
          <v-chip v-if="status === 'on'" color="success" label>
            <v-icon left>
              domain
            </v-icon>
            Online
          </v-chip>
          <v-chip v-else-if="status === 'off'" color="error" label>
            <v-icon left>
              domain_disabled
            </v-icon>
            Offline
          </v-chip>
          <div v-else>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-text-field
          outlined
          dense
          v-model="config.host"
          label="Host"
          readonly
        />
        <v-text-field
          outlined
          dense
          v-model="config.port"
          label="Port"
          readonly
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["settings"],

  data: () => ({
    config: {},
    status: null
  }),

  created() {
    this.getCrestronConfig();
    this.getCrestronStatus();
  },

  methods: {
    /**
     * Get Crestron status.
     */
    async getCrestronConfig() {
      try {
        this.status = null;
        const response = await this.$api.http.get(
          "backoffice/settings/crestron-config"
        );
        this.config = response.data;
      } catch (error) {
        this.config = {};
      }
    },

    /**
     * Get Crestron status.
     */
    async getCrestronStatus() {
      try {
        this.status = null;
        const response = await this.$api.http.get(
          "backoffice/settings/crestron-status"
        );
        this.status = response.data.status;
      } catch (error) {
        this.status = "off";
      }
    }
  }
};
</script>

<style></style>
